<template>
  <div>
    <b-card class="card-statistics">
      <app-collapse>
        <app-collapse-item title="Filtros">
          <div
            class="mb-1 col-md-5"
          >
            <label>Credores</label>
            <v-select
              id="forma-recebimento"
              v-model="creditorsFilter"
              :options="creditors"
              :close-on-select="false"
              multiple
              class="mb-1"
            />
            <label>Usuário</label>
            <v-select
              id="forma-recebimento"
              v-model="usersFilter"
              class="mb-1"
              multiple
              :options="users"
              :close-on-select="false"
            />
            <label>Motivo de Evento</label>
            <v-select
              id="forma-recebimento"
              v-model="reasonEventFilter"
              :options="reasonEvent"
              :close-on-select="false"
              multiple
              class="mb-1"
            />

            <label for="example-datepicker">Data inicial</label>
            <b-form-input
              id="example-datepicker"
              v-model="date.date_initial"
              locale="pt"
              type="date"
              class="mb-1"
            />

            <label for="example-datepicker">Data final</label>
            <b-form-input
              id="example-datepicker"
              v-model="date.date_finaly"
              locale="pt"
              type="date"
              class="mb-2"
            />

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="filter"
            >
              Filtrar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="resetFilter"
            >
              Resetar filtro
            </b-button>
          </div>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <b-overlay
      :show="load"
      variant="transparent"
    >
      <b-card v-show="showListFiltered">
        <b-col>
          <h3>Relatório de eventos</h3>
        </b-col>
        <b-card
          class="padding-0"
          padding="0"
        >
          <b-row>
            <b-col
              md="4"
            >
              <label>Filtro por eventos</label>
              <v-select
                v-model="eventosFiltro"
                :options="motivoEventos"
                multiple
              />
            </b-col>
            <b-col
              md="2"
              class="align-items-bottom"
            >
              <b-button
                variant="outline-warning"
                @click="exportFile"
              >
                Exportar excel
              </b-button>
            </b-col>
            <b-col
              md="2"
              class="align-items-bottom"
            >
              <b-button
                variant="outline-info"
                @click="exportPdf"
              >
                Exportar PDF
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-table
          small
          striped
          responsive
          :fields="fields"
          :items="listaComFiltro"
          :per-page="itemPerPage"
          :current-page="currentPage"
        >
          <template #cell(show_details)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
              <span class="vs-label">
                {{ row.detailsShowing ? 'Esconder' : 'Mostrar' }}
              </span>
            </b-form-checkbox>
          </template>

          <template #row-details="row">
            <b-card>
              <div v-html="row.item.descricao" />
            <!-- <strong>
              {{ row.item.descricao }}
            </strong> -->
            </b-card>
          </template>

          <template #cell(evento_created_at)="data">
            {{ formatTimezone(data.item.evento_created_at) }}
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <b-pagination
      v-model="currentPage"
      :per-page="itemPerPage"
      :total-rows="totalRows"
      hide-footer
      align="center"
      size="sm"
      class="my-0"
    />

    <b-modal
      id="modal-relatorio"
      ref="modal-relatorio"
      size="xl"
      title=""
    >
      <b-row>
        <b-col>
          <iframe
            id="myFrame"
            :src="relatorioPdfURL"
          />
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BPagination, BTable, BFormCheckbox, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import axios from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BCol,
    BRow,
    BFormCheckbox,
    BTable,
    BPagination,
    BCard,
    AppCollapse,
    AppCollapseItem,
    BButton,
    BFormInput,
    vSelect,
    BOverlay,
  },

  data() {
    return {
      eventosFiltro: [],
      totalRows: 1,
      currentPage: 1,
      itemPerPage: 20,

      relatorioPdfURL: null,

      itemsReport: [],
      showListFiltered: false,
      fields: [
        { key: 'show_details', label: 'Mostrar Detalhes' },
        { key: 'cliente_id', label: 'Client_ID' },
        { key: 'cpf_cnpj', label: 'Cpf_cnpj' },
        { key: 'credor_id', label: 'Credor_ID' },
        { key: 'evento_created_at', label: 'Data criação evento' },
        { key: 'motivo_id', label: 'Motivo_ID' },
        { key: 'nome_cliente', label: 'Cliente' },
        { key: 'nome_credor', label: 'Credor' },
        { key: 'nome_evento', label: 'Evento' },
        { key: 'user_name', label: 'Usuário' },
      ],
      date: {
        date_initial: '',
        date_finaly: '',
      },
      creditors: [],
      reasonEvent: [],
      users: [],
      creditorsFilter: [],
      reasonEventFilter: [],
      usersFilter: [],
      load: false,
    }
  },

  computed: {
    motivoEventos() {
      const { itemsReport } = this
      const opcoes = []

      itemsReport.map(item => {
        opcoes.push(item.nome_evento)
      })

      const retiraDuplicados = [...new Set(opcoes)]
      return retiraDuplicados
    },

    listaComFiltro() {
      const { itemsReport, eventosFiltro: ef } = this

      let itens = itemsReport

      if (ef.length > 0) {
        for (let i = 0; i <= ef.length - 1; i++) {
          // eslint-disable-next-line consistent-return
          itens = itemsReport.map(item => {
            if (item.nome_evento === ef[i]) {
              return item
            }
          }).filter(Boolean)
        }
      }

      return itens
    },
  },

  async created() {
    await axios.get('api/v1/credores/listar/false', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(item => {
        this.creditors.push({
          label: item.nome,
          id: item.id,
        })
      })
    })

    await axios.get('api/v1/motivo_evento', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(item => {
        this.reasonEvent.push({
          label: item.nome,
          id: item.id,
        })
      })
    })

    await axios.get('api/v1/users', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(item => {
        this.users.push({
          label: item.name,
          id: item.id,
        })
      })
    })

    const filter = JSON.parse(localStorage.getItem('filter'))
    this.reasonEventFilter = filter.reasonEvent
    this.creditorsFilter = filter.creditors
    this.usersFilter = filter.users
    this.date.date_initial = filter.dataInitial
    this.date.date_finaly = filter.dataFinaly
  },

  methods: {
    async filter() {
      const cred = []
      this.creditorsFilter.map(creditor => {
        cred.push(creditor.id)
      })

      const motId = []
      this.reasonEventFilter.map(eventFilter => {
        motId.push(eventFilter.id)
      })

      const userName = []
      this.usersFilter.map(name => {
        userName.push(name.label)
      })

      const filterDetails = {
        credores_id: cred,
        data_inicio: this.date.date_initial,
        data_final: this.date.date_finaly,
        motivos: motId,
        funcionarios: userName,
      }

      if (
        filterDetails.data_inicio === ''
        || filterDetails.data_final === ''
      ) {
        this.$swal({
          icon: 'error',
          title: 'Erro na seleção',
          text: 'Selecione as datas',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.load = true
        await axios.post('api/v1/relatorios/backoffice/listar/eventos', filterDetails, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        }).then(res => {
          this.load = false
          this.showListFiltered = true
          this.itemsReport = res.data
          this.totalRows = res.data.length
        }).catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro',
            text: `Ops, algo deu errado: ${error.response.data}`,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })

        const remember = {
          dataInitial: filterDetails.data_inicio,
          dataFinaly: filterDetails.data_final,
          creditors: this.creditorsFilter,
          reasonEvent: this.reasonEventFilter,
          users: this.usersFilter,
        }

        localStorage.setItem('filter', JSON.stringify(remember))
      }
    },

    resetFilter() {
      this.reasonEventFilter = []
      this.creditorsFilter = []
      this.usersFilter = []
      this.date.date_initial = ''
      this.date.date_finaly = ''
      localStorage.removeItem('filter')
    },

    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      const itemsPlan = this.listaComFiltro

      worksheet.columns = [
        { header: 'cliente_ID' },
        { header: 'cpf_cnpj' },
        { header: 'credor_ID' },
        { header: 'Descricao' },
        { header: 'Data evento' },
        { header: 'motivo_ID' },
        { header: 'Cliente' },
        { header: 'Credor' },
        { header: 'Nome evento' },
        { header: 'Usuário' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.cliente_id,
          item.cpf_cnpj,
          item.credor_id,
          item.descricao,
          this.formatTimezone(item.evento_created_at),
          item.motivo_id,
          item.nome_cliente,
          item.nome_credor,
          item.nome_evento,
          item.user_name,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de eventos', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },

    async exportPdf() {
      const { listaComFiltro: eventos } = this

      const credor = this.creditorsFilter[0]

      const htmlTags = ['<br>', '<strong>']

      const eventosData = eventos.map(event => ({
        dataHora: event.evento_created_at,
        cliente: {
          nome: event.nome_cliente,
          cpf: this.cpfLabel(event.cpf_cnpj),
        },
        type: htmlTags.filter(tag => event.descricao.includes(tag)).length > 0 ? 'html' : 'text',
        motivo: event.nome_evento,
        mensagem: event.descricao,
      }))

      const body = {
        periodo: {
          inicio: this.americaDate(this.date.date_initial),
          fim: this.americaDate(this.date.date_finaly),
        },
        credor: credor ? credor.label : '',
        eventos: eventosData,
      }

      const relatorio = await axios.post(`${'http://192.168.254.216:3001'}/relatorio-eventos`,
        body, { responseType: 'arraybuffer' }).then(res => new File([res.data], 'Relatório.pdf', {
        type: 'application/pdf',
      }))

      this.relatorioPdfURL = URL.createObjectURL(relatorio)
      this.$refs['modal-relatorio'].show()
    },

  },

}
</script>

<style>
  .align-items-bottom{
    display: flex;
    align-items: flex-end;
  }
</style>
